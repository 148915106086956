/* POPPINS FONT DEFINITION */


/* TAILWIND IMPORT */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* CUSTOM CSS */
html, body {
  width: 100%;
  height: 100%;
  padding: 0;
}

html, body {
  /* @apply overflow-x-hidden; */
}

/*SPAZI 4i*/

.spazi-4i-title {
  @apply relative inline;
}

.spazi-4i-title::before {
  content: " ";
  @apply absolute top-0 left-0 h-2 w-16 bg-4i-yellow;
}

.spazi-4i-title::after {
  content: " ";
  @apply absolute -bottom-2 right-0 h-2 w-16 bg-4i-violet;
}

@screen xs {
  .spazi-4i-title::before {
    @apply w-24 h-3;
  }

  .spazi-4i-title::after {
    @apply w-24 h-3;
  }
}

@screen md {
  .spazi-4i-title::before {
    @apply w-32 h-3;
  }

  .spazi-4i-title::after {
    @apply w-32 h-3;
  }
}



/*COLLAGE HERO*/
.collage-el {
  opacity: 0;
}

/* HONEYPOT STYLE FOR SUPPORT FOR */
div.support-form:nth-of-type(5) {
  display: none !important;
}

div.register-form:nth-of-type(5) {
  display: none !important;
}

div.newsletter-form:nth-of-type(2) {
  display: none !important;
}